<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="
              `/tests/${$route.params.testType}/edit/test?testTypeId=${$route.params.testTypeId}`
            "
          >
            {{ testName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h1>
        {{ pageTitle }}
        <router-link
          :to="{
            path: `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/passage/create`
          }"
        >
          <i class="el-icon-plus" />
        </router-link>
      </h1>
      <el-table :data="passages" width="100%">
        <el-table-column label="Content" width="800px">
          <template slot-scope="scope">
            <div v-html="scope.row.content.slice(0, 100)" />
            <router-link
              :to="
                `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/passage/${scope.row.id}`
              "
            >
              {{ `${scope.row.content.length > 100 ? "More..." : ""}` }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="Question Count">
          <template slot-scope="scope">
            <router-link
              :to="
                `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/passage/${scope.row.id}`
              "
            >
              {{ scope.row.question_count }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Action" width="100">
          <template slot-scope="scope">
            <router-link
              :to="
                `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/passage/${scope.row.id}`
              "
            >
              <i class="fa fa-eye action-icon" />
            </router-link>
            <router-link
              :to="
                `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/passage/${scope.row.id}/edit`
              "
            >
              <i class="fa fa-edit" />
            </router-link>
            <DeleteIconButton :onDelete="deletePassage(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import DeleteIconButton from "@/components/button/DeleteIconButton";
import Breadcrumb from "@/components/Breadcrumb";
import testsApi from "@/apis/tests.js";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb,
    DeleteIconButton
  },

  props: [],
  data() {
    return {
      sectionName: "",
      testName: "",
      passages: []
    };
  },
  computed: {
    pageTitle() {
      return "Passages";
    }
  },
  watch: {},

  async created() {
    await this.fetchPassages();
    const { full_name, subjects } = await testsApi.getTest(
      this.$route.params.testType,
      this.$route.params.testTypeId
    );
    this.testName = full_name;
    this.sectionName = subjects.find(
      subject => String(subject.id) === this.$route.params.sectionId
    ).name;
  },
  methods: {
    async fetchPassages() {
      this.passages = await testsApi.getSubjectPassages(
        this.$route.params.testType,
        this.$route.params.sectionId
      );
    },
    deletePassage(passageId) {
      return async () => {
        await testsApi.deletePassage(this.$route.params.testType, passageId);
        this.fetchPassages();
      };
    }
  }
};
</script>

<style scoped></style>
